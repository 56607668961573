import { isExpiringSoon, isInFuture, isInPast } from '../../../../play-core/models/Media';
import { dateOrWeekdayFormat, distanceExpiringSoonFormat, shortTimeFormat } from '../../utils/date-formatter';
export const getLivestreamLabelProps = (t, media) => {
  const {
    type,
    validFrom,
    validTo
  } = media || {};
  if (isLivestream(type)) {
    if (validFrom && isInFuture(validFrom)) {
      const text = `${dateOrWeekdayFormat(validFrom, t, 0)}, ${shortTimeFormat(validFrom)}`;
      return getComingSoonLabelProps(text);
    }
    if (isInPast(validTo)) {
      return;
    }
    return {
      type: 'live',
      text: t('global.live').toUpperCase()
    };
  }
};
const isLivestream = type => type === 'LIVESTREAM' || type === 'SCHEDULED_LIVESTREAM';
export const getExpiringSoonLabelProps = (media, minHourDifference, t) => {
  const {
    validTo,
    type
  } = media || {};
  return validTo && !isLivestream(type) && isExpiringSoon(validTo, minHourDifference) ? {
    type: 'expiringSoon',
    text: distanceExpiringSoonFormat(validTo, t)
  } : undefined;
};
export const getDefaultLabelProps = text => text?.trim() ? {
  type: 'default',
  text
} : undefined;
export const getComingSoonLabelProps = text => text?.trim() ? {
  type: 'comingSoon',
  text
} : undefined;
export const getLabelMap = (t, media, text) => {
  const labelMap = {
    expiringSoon: () => getExpiringSoonLabelProps(media, 48, t),
    live: () => getLivestreamLabelProps(t, media),
    default: () => getDefaultLabelProps(text)
  };
  return labelMap;
};
export const getLabel = (labelOrder, labelMap) => labelOrder.reduce((acc, cur) => acc || labelMap[cur](), undefined);