import { differenceInMinutes, formatISODuration, isFuture, isPast } from 'date-fns';
export const leadAndDescription = media => {
  if (media.lead && media.description) {
    return `${media.lead}\n${media.description}`;
  }
  return media.lead ? media.lead : media.description || '';
};
export const isLivestream = media => ['SCHEDULED_LIVESTREAM', 'LIVESTREAM'].includes(media.type ?? '');
export const isInFuture = date => date && isFuture(new Date(date));
export const isInPast = date => date && isPast(new Date(date));
export const isExpiringSoon = (validTo, minHourDifference) => {
  if (validTo) {
    const dateDifferenceInMinutes = differenceInMinutes(new Date(validTo), new Date());
    const dateDifferenceInHours = dateDifferenceInMinutes / 60;
    return dateDifferenceInHours <= minHourDifference && dateDifferenceInMinutes >= 0;
  }
  return false;
};
export const toIsoDuration = durationMs => {
  const durationInSeconds = Math.floor(durationMs / 1000) % 60;
  const durationInMinutes = Math.floor(durationMs / (1000 * 60)) % 60;
  const durationInHours = Math.floor(durationMs / (1000 * 60 * 60));
  return formatISODuration({
    seconds: durationInSeconds,
    minutes: durationInMinutes,
    hours: durationInHours
  });
};